import React, {useState, useEffect} from "react"
import { graphql } from 'gatsby'
import { Layout, Product, Navbar, BigMenu, Seo } from '../../../components'

export default function ProductPage({data}) {  console.log("DATA", data)
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(()=>{
      setHasMounted(true)
  },[])
  if(!hasMounted)return null
  return(
    <Seo title={data.datoCmsProduct.name} schema={ProductSchema(data.datoCmsProduct)}>
      <Layout>
        <Navbar/>
        <BigMenu/>
        <Product product={data.datoCmsProduct}/>
      </Layout>
    </Seo>
  )
}

export const query = (graphql`
query ($name:String!) {
    datoCmsProduct(name:{eq:$name}) {
        id
        name
        price
        description
        listSeries
        longDescription{
          value
        }
        image {
            url
            sizes(maxWidth: 300, maxHeight:300, imgixParams: { fm: "webp" }) {
                ...GatsbyDatoCmsSizes
            }
        }
    }
}
`)

const ProductSchema = (product) => (
  JSON.stringify({
      "@context":"https://schema.org",
      "@type":"Product",
      "description":product.description,
      "name":product.name,
      "image":product.image.url,
      "offers":{
          "@type":"Offer",
          "price":product.price,
          "priceCurrency":"CAD"
      },
      "brand":{
        "@type":"Brand",
        "logo":"https://www.datocms-assets.com/44515/1616499733-logoblack.png"
      }
  })
)